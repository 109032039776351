html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  scroll-padding-top: 6rem;
  background-color: #FBFFFE;
}

@media(min-width: 1800px) {
  html {
    font-size: 69.5%;
  }
}

@media(max-width: 1600px) {
  html {
    font-size: 65.5%;
  }
}

@media(max-width: 1440px) {
  html {
    font-size: 55.5%;
  }
}

@media(max-width: 1200px) {
  html {
    font-size: 50.5%;
  }
}

@media(max-width: 900px) {
  html {
    font-size: 55.5%;
  }
}

@media(max-width: 400px) {
  html {
    font-size: 47%;
  }

  h1 {
    font-size: 3.5rem !important;
  }
}

* {
  margin: 0px;
  padding: 0px;
  outline: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 3rem;
  color: #030D1F;
  text-transform: uppercase;
}

h3,
h4 {
  font-size: 2.8rem;
}

p,
span {
  font-size: 2rem;
  font-family: 'Open-sans', sans-serif;
  font-weight: 500;
  line-height: 3rem;
}

strong {
  font-size: 2.2rem;
  font-family: 'Open-sans', sans-serif;
  font-weight: 800;
}

button {
  font-family: 'Rubik', sans-serif;
}

img,
video {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}